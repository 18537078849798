/* Глобальные стили */
@import "./styles/colors.styl"

.text-bold 
  font-weight: 600

input, textarea
  &.ng-touched.ng-invalid
    border-color: red

  &.ng-touched.ng-valid
    border-color: green

.fw-500
  font-weight: 500

.section
  padding: 20px 0
  min-height: calc(100vh - 53px - 45px)

.title
  font-size: 24px

.invalid-feedback.show
  display: block

.fancybox__container .carousel__button.is-close
  right: -36px

.container-fluid
  padding-left: 20px
  padding-right: 20px

